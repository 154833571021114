<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols="12">
          <v-card-title >
            8. Хранение, обращение, транспортирование ЛВЖ, ГЖ, ГГ.
          </v-card-title>
          <v-card-title class="red--text pb-0 mb-0">
            На объектах запрещается:
          </v-card-title>
        </v-col>
        <v-col cols="6">
          <v-card-text class="title font-weight-light">
            <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
            оставлять емкости с ЛВЖ, ГЖ, ГГ (горючими газами) на прилегающее к объекту территориях;
          </v-card-text>
          <v-card-text class="title font-weight-light">
            <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
            хранить и применять на чердаках, в подвальных, цокольных и подземных этажах, под свайным пространством зданий ЛВЖ, ГЖ и другие
            <v-tooltip bottom color="red">
              <template v-slot:activator="{ on }">
                <span
                  class="blue lighten-4"
                  v-on="on"
                >пожаровзрывоопасные вещества и материалы;</span>
              </template>
              <span class="pa-5 subtitle-1 text-center">порох, взрывчатые вещества, пиротехнические изделия, баллоны с горючими газами, товары в аэрозольной упаковке, отходы любых классов опасности</span>
            </v-tooltip>

          </v-card-text>
          <v-card-text class="title font-weight-light">
            <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
            применять в качества топлива
            <v-tooltip bottom color="red">
              <template v-slot:activator="{ on }">
                <span
                  class="blue lighten-4"
                  v-on="on"
                >ЛВЖ и ГЖ, </span>
              </template>
              <span class="pa-5 subtitle-1 ">в том числе отходы нефтепродуктов</span>
            </v-tooltip>

            которые не предусмотрены техническими условиями на эксплуатацию оборудования;
          </v-card-text>
        </v-col>
        <v-col cols="6">
          <v-card-text class="title font-weight-light">
            <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
            проводить уборку помещений и стирку одежды с применением ЛВЖ и ГЖ (бензин, керосин и др.);
          </v-card-text>
          <v-card-text class="title font-weight-light">
            <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
            сливать ЛВЖ и ГЖ в канализационные сети;
          </v-card-text>
          <v-card-text class="title font-weight-light">
            <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
            отогревать коммуникации, транспортирующие или содержащие горючие вещества
            <v-tooltip bottom color="red">
              <template v-slot:activator="{ on }">
                <span
                  class="blue lighten-4"
                  v-on="on"
                >открытым огнем;</span>
              </template>
              <span class="pa-5 subtitle-1 ">паяльными лампами и др.</span>
            </v-tooltip>

          </v-card-text>

          <v-card-text class="title font-weight-light">
            <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
            использовать для перевозки автомобили без заземления, средства пожаротушения, маркировки степени опасности груза, исправных искрогасителей.
          </v-card-text>
        </v-col>
        <v-col cols="4">

          <v-dialog
            v-model="dialog1"
            width="80%"
            class="pa-16"
          >
            <template v-slot:activator="{ on, attrs }">
                <v-card
                  class="pa-2 d-flex justify-center align-center"
                  full-width
                  v-bind="attrs"
                  v-on="on"
                  tag="a"
                  height="110"

                  >
                <v-img
                  lazy-src="img/material/cart.svg"
                  max-width="80"
                  src="img/material/cart.svg"
                  class="ma-1"
                ></v-img>
                <v-spacer></v-spacer>
                <v-card-text class="title font-weight-light">
                  Объекты организаций торговли
                </v-card-text>
              </v-card>
            </template>
            <v-card class="noselect">
              <v-card-title class="headline mb-5" dark>
                Объекты организаций торговли
              </v-card-title>
              <v-card-text class="title font-weight-light">
                <span class="red--text font-weight-bold">На объектах организаций торговли ЗАПРЕЩАЕТСЯ:</span>
              </v-card-text>

              <v-card-text class="title font-weight-light">
                <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                осуществлять продажу ЛВЖ и ГЖ, ГГ (в том числе баллонов с газом, лакокрасочных изделий, растворителей, товаров в аэрозольной упаковке), пороха, капсюлей, пиротехнических и других взрывоопасных изделий, если здание не является зданием класса функциональной пожарной опасности Ф 3.1;
              </v-card-text>

              <v-card-text class="title font-weight-light">
                <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                размещать отделы, секции по продаже ЛВЖ и ГЖ, ГГ и пиротехнических изделий на расстоянии менее 4 метров от выходов, лестничных клеток и других путей эвакуации;
              </v-card-text>

              <v-card-text class="title font-weight-light mb-5">
                <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                осуществлять продажу товаров бытовой химии, ЛВЖ и ГЖ (лаки, краски и др.), расфасованных в стеклянную тару емкостью более 1 литра, а также пожароопасными товарами без этикетов с предупреждающими надписями «Огнеопасно».
              </v-card-text>

              <v-card-text class="title font-weight-bold">
                Хранение и продажа ГЖ путем налива в тару разрешается в отдельно стоящих зданиях:
              </v-card-text>

              <v-card-text class="title font-weight-light">
                <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                выполненных из негорючих материалов, включая полы;
              </v-card-text>

              <v-card-text class="title font-weight-light">
                <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                с уровнем пола ниже примыкающей планировочной отметки, чтобы исключалось растекание жидкости при аварии за его пределы;
              </v-card-text>

              <v-card-text class="title font-weight-light mb-5">
                <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                без печного отопления и использования приборов, устройств с применением открытого огня.
              </v-card-text>

              <v-card-text class="title font-weight-light">
                <p>Торговые залы отделяются противопожарными преградами от кладовых с емкостями ГЖ.</p>
                <p>Емкости (резервуары, бочки) должны быть не более 5м³.</p>
                <p>Тара из-под ЛВЖ и ГЖ хранится на специальных огражденных площадках.</p>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="red"
                  text
                  @click="dialog1 = false"
                >
                  Закрыть
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>
        <v-col cols="4">

          <v-dialog
            v-model="dialog2"
            width="50%"
            class="pa-16"
          >
            <template v-slot:activator="{ on, attrs }">
                <v-card
                  class="pa-2 d-flex justify-center align-center"
                  full-width
                  v-bind="attrs"
                  v-on="on"
                  tag="a"
                  height="110"

                  >
                <v-img
                  lazy-src="img/material/caduceus.svg"
                  max-width="80"
                  src="img/material/caduceus.svg"
                  class="ma-1"
                ></v-img>
                <v-spacer></v-spacer>
                <v-card-text class="title font-weight-light">
                  Медицинские организации
                </v-card-text>
              </v-card>
            </template>
            <v-card class="noselect">
              <v-card-title class="headline mb-5" dark>
                Медицинские организации
              </v-card-title>
              <v-card-text class="title font-weight-light">
                Допускается хранение лекарственных препаратов и медицинских изделий, относящихся к ЛВЖ и ГЖ (спирт, эфир и др.), общим весом не более 3кг с учетом их совместимости в закрывающихся на замок металлических шкафах.
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="red"
                  text
                  @click="dialog2 = false"
                >
                  Закрыть
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>
        <v-col cols="4">

          <v-dialog
            v-model="dialog3"
            width="50%"
            class="pa-16"
          >
            <template v-slot:activator="{ on, attrs }">
                <v-card
                  class="pa-2 d-flex justify-center align-center"
                  full-width
                  v-bind="attrs"
                  v-on="on"
                  tag="a"
                  height="110"

                  >
                <v-img
                  lazy-src="img/material/power-plant.svg"
                  max-width="80"
                  src="img/material/power-plant.svg"
                  class="ma-1"
                ></v-img>
                <v-spacer></v-spacer>
                <v-card-text class="title font-weight-light">
                  Производственные объекты
                </v-card-text>
              </v-card>
            </template>
            <v-card class="noselect">
              <v-card-title class="headline mb-5" dark>
                Производственные объекты
              </v-card-title>
              <v-card-text class="title font-weight-light">
                <p>Для мойки и обезжиривания оборудования, изделий и деталей применяются негорючие технические моющие средства.</p>
                <p><strong>Исключение:</strong> если применение ЛВЖ и ГГ предусмотрено по условиям технологического процесса.</p>
              </v-card-text>

              <v-card-text class="title red--text font-weight-bold">
                ЗАПРЕЩЕНО:
              </v-card-text>

              <v-card-text class="title font-weight-light">
                <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                проводить отбор проб ЛВЖ и ГЖ из резервуаров и замер их уровня во время грозы, закачки или откачки продукта. Данные операции проводят в светлое время суток;
              </v-card-text>

              <v-card-text class="title font-weight-light mb-5">
                <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                подавать ЛВЖ И ГЖ в резервуары падающей струей. Скорость наполнения и опорожнения не должна превышать суммарную пропускную способность установленных на резервуарах дыхательных клапанов (вентиляционных патрубков).
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="red"
                  text
                  @click="dialog3 = false"
                >
                  Закрыть
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>
        <v-col cols="4">

          <v-dialog
            v-model="dialog4"
            width="50%"
            class="pa-16"
          >
            <template v-slot:activator="{ on, attrs }">
                <v-card
                  class="pa-2 d-flex justify-center align-center"
                  full-width
                  v-bind="attrs"
                  v-on="on"
                  tag="a"
                  height="110"

                  >
                <v-img
                  lazy-src="img/material/truck.svg"
                  max-width="80"
                  src="img/material/truck.svg"
                  class="ma-1"
                ></v-img>
                <v-spacer></v-spacer>
                <v-card-text class="title font-weight-light">
                  Объекты транспортной инфраструктуры
                </v-card-text>
              </v-card>
            </template>
            <v-card class="noselect">
              <v-card-title class="headline" dark>
                Объекты транспортной инфраструктуры
              </v-card-title>
              <v-card-text class="title font-weight-light">
                <span class="red--text font-weight-bold">На объектах транспортной инфраструктуры ЗАПРЕЩЕНО:</span>
              </v-card-text>

              <v-card-text class="title font-weight-light mb-5">
                <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                производить кузнечные, термические, сварочные, малярные и деревообделочные работы, промывку деталей с использованием ЛВЖ и ГЖ.
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="red"
                  text
                  @click="dialog4 = false"
                >
                  Закрыть
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>
        <v-col cols="4">

          <v-dialog
            v-model="dialog5"
            width="50%"
            class="pa-16"
          >
            <template v-slot:activator="{ on, attrs }">
                <v-card
                  class="pa-2 d-flex justify-center align-center"
                  full-width
                  v-bind="attrs"
                  v-on="on"
                  tag="a"
                  height="110"

                  >
                <v-img
                  lazy-src="img/material/tower.svg"
                  max-width="80"
                  src="img/material/tower.svg"
                  class="ma-1"
                ></v-img>
                <v-spacer></v-spacer>
                <v-card-text class="title font-weight-light">
                  Электростанции
                </v-card-text>
              </v-card>
            </template>
            <v-card class="noselect">
              <v-card-title class="headline" dark>
                Электростанции
              </v-card-title>
              <v-card-text class="title font-weight-light">
                <span class="red--text font-weight-bold">В помещениях и коридорах, закрытых РУ (распределительных устройств) и подстанции ЗАПРЕЩЕНО:</span>
              </v-card-text>

               <v-card-text class="title font-weight-light mb-5">
                <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                устраивать кладовые, не относящиеся к РУ;
              </v-card-text>

               <v-card-text class="title font-weight-light mb-5">
                <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                хранить электротехническое оборудование, запасные части, емкости с ГЖ и баллоны с различными газами.
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="red"
                  text
                  @click="dialog5 = false"
                >
                  Закрыть
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>
        <v-col cols="4">

          <v-dialog
            v-model="dialog6"
            width="70%"
            class="pa-16"
          >
            <template v-slot:activator="{ on, attrs }">
                <v-card
                  class="pa-2 d-flex justify-center align-center"
                  full-width
                  v-bind="attrs"
                  v-on="on"
                  tag="a"
                  height="110"

                  >
                <v-img
                  lazy-src="img/material/warehouse.svg"
                  max-width="80"
                  src="img/material/warehouse.svg"
                  class="ma-1"
                ></v-img>
                <v-spacer></v-spacer>
                <v-card-text class="title font-weight-light">
                  Объекты хранения (склады ЛВЖ и ГЖ)
                </v-card-text>
              </v-card>
            </template>
            <v-card class="noselect">
              <v-card-title class="headline mb-5" dark>
                Объекты хранения (склады ЛВЖ и ГЖ)
              </v-card-title>
                <v-tabs  color="red"  grow v-model="tab">
                  <v-tab class="red lighten-5 mr-5">Общие требования</v-tab>
                  <v-tab class="red lighten-5 mr-5">Запрещено</v-tab>
                  <v-tab class="red lighten-5">Категории складов</v-tab>
                </v-tabs>
                <v-tabs-items v-model="tab">

                  <v-tab-item>
                    <v-container fluid>
                      <v-row>
                        <v-col>
                          <v-card-text class="title font-weight-light">
                            <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                            дыхательные клапаны и огнепреградители проверять в соответствии с технической документацией предприятий-изготовителей;
                          </v-card-text>
                          <v-card-text class="title font-weight-light">
                            <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                            дыхательные клапаны и огнепреградители проверять в соответствии с технической документацией предприятий-изготовителей;
                          </v-card-text>
                          <v-card-text class="title font-weight-light">
                            <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                            отбор проб и замер уровня жидкости в резервуаре производить при помощи приспособлений из материалов, исключающих искрообразование;
                          </v-card-text>
                          <v-card-text class="title font-weight-light">
                            <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                            хранить жидкости только в исправной таре;
                          </v-card-text>
                          <v-card-text class="title font-weight-light">
                            <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                            немедленно убирать пролитую жидкость;
                          </v-card-text>
                          <v-card-text class="title font-weight-light">
                            <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                            защищать баллоны с ГГ, емкости с ЛВЖ и ГЖ от солнечного и иного теплового воздействия;
                          </v-card-text>
                          <v-card-text class="title font-weight-light">
                            <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                            вскрытые тары, проверка исправности и мелкий ремонт, расфасовка продукции, приготовленные рабочих смесей пожараоопасных жидкостей (нитрокрасок, лаков и других горючих жидкостей) производить в помещениях, изолированных от мест хранения.
                          </v-card-text>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-tab-item>

                  <v-tab-item>
                    <v-container fluid>
                      <v-row>
                        <v-col>
                          <v-card-text class="title font-weight-light">
                            <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                            эксплуатация негерметичного оборудования и запорной арматуры;
                          </v-card-text>
                          <v-card-text class="title font-weight-light">
                            <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                            эксплуатация резервуаров с дефектами (перекосы и трещины, проемы или трещины на плавающих крышах), неисправного оборудования, контрольно-измерительных приборов, подводящих продуктопроводов и стационарных противопожарных устройств;
                          </v-card-text>
                          <v-card-text class="title font-weight-light">
                            <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                            наличие деревьев, кустарников с сухой растительностью внутри обвалований;
                          </v-card-text>
                          <v-card-text class="title font-weight-light">
                            <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                            установка емкостей (резервуаров) на основание, выполненное из горючих материалов;
                          </v-card-text>
                          <v-card-text class="title font-weight-light">
                            <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                            переполнение резервуаров и цистерн;
                          </v-card-text>
                          <v-card-text class="title font-weight-light">
                            <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                            отбор проб из резервуаров во время слива или налива нефти и нефтепродуктов во время грозы;
                          </v-card-text>
                          <v-card-text class="title font-weight-light">
                            <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                            хранение ЛВЖ и ГЖ в количестве, превышающем установленные нормы;
                          </v-card-text>
                          <v-card-text class="title font-weight-light">
                            <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                            разлив нефтепродуктов, хранение упаковочного материала и тары в хранилищах и на обвалованных площадках.
                          </v-card-text>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-tab-item>

                  <v-tab-item>
                    <v-container fluid>
                      <v-row>
                        <v-col>
                          <v-card-text class="title pa-10">
                            ФЗ №123 «Технический регламент о требованиях пожарной безопасности», таблица «Категории складов для хранения нефти и нефтепродуктов»:
                          </v-card-text>
                            <v-simple-table class="ptm-table elevation-1 ma-5">
                              <template v-slot:default>
                                <thead>
                                  <tr>
                                    <th dark class="red white--text text-center title">
                                      Категории склада
                                    </th>
                                    <th class="red white--text text-center title">
                                      Максимальный размер одного резервуара, м³
                                    </th>
                                    <th class="red white--text text-center title">
                                      Вместимость склада, м³
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr v-for="(item, index) in table" :key="index">
                                    <td class="title text-center font-weight-light">{{ item.category }}</td>
                                    <td class="text-center title font-weight-light">{{ item.count }}</td>
                                    <td class="title text-center font-weight-light">{{ item.capacity }}</td>
                                  </tr>
                                </tbody>
                              </template>
                            </v-simple-table>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-tab-item>

                </v-tabs-items>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="red"
                  text
                  @click="dialog6 = false"
                >
                  Закрыть
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>

      </v-row>
      <Hint></Hint>
    </v-container>
  </div>
</template>
<script>
import Hint from '@/components/Hint.vue'
export default {
  data: () => ({
    dialog1: null,
    dialog2: null,
    dialog3: null,
    dialog4: null,
    dialog5: null,
    dialog6: null,
    tab: null,
    table: [
      {
        category: 'I',
        count: '-',
        capacity: 'более 100 000'
      },
      {
        category: 'II',
        count: '-',
        capacity: 'более 20 000, но не более 100 000'
      },
      {
        category: 'IIIа',
        count: 'не более 5000',
        capacity: 'более 10 000, но не более 20 000'
      },
      {
        category: 'IIIб',
        count: 'не более 2000',
        capacity: 'более 2 000, но не более 10 000'
      },
      {
        category: 'IIIв',
        count: 'не более 700',
        capacity: 'не более 2 000'
      }
    ]
  }),
  components: {
    Hint
  }
}
</script>
<style lang="less" scoped>
.ptm-table table {
  border-spacing: 20px;
}
</style>
